import styles from './ForgotPassword.module.css'
import { Navigate } from 'react-router-dom';
import { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import alaxs_logo from '../../img/alaxs_logo.png';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import firebaseApp from '../../firebase/firebaseApp';
import Message from '../Message';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    
    const userLoggedIn = useSelector((state) => state.user.loggedIn);

    const auth = getAuth(firebaseApp);
    
    const loginHandler = () => {
        if (email.length > 0)
        {
            sendPasswordResetEmail(auth, email, {
                url: process.env.REACT_APP_EMAIL_URL_CALLBACK
            }).then(() => {
                setEmailSent(true);
            }).catch((error) => {
                setErrorMessage('Hubo un error al intentar recuperar la contraseña.');
                console.log(error.message);
            });
        }
    }

    if (emailSent)
    {
        return <Message url="/login" title="Recuperación de Clave" message="Se le ha envíado un correo para recuperar su clave." buttonTitle="Retornar" />
    }

    const changeEmailHandler = (e) => {
        setEmail(e.target.value);
    }

    return <Fragment>
        {userLoggedIn && <Navigate to="/" />}
        <main className={styles.main}>
    <div className={styles.container}>
        <div className={styles.left} />
        <div className={styles.right}>
            <div className={styles.logo}>
                <img src={alaxs_logo} alt="Logo ALAXS" />
            </div>
            <div className={styles.controls}>
                <h5>Olvidé mi clave</h5>
                <input type="email" placeholder="Email" className={styles.textinput} onChange={changeEmailHandler} />
                <span>{errorMessage}</span>
                <button className={styles.button} onClick={loginHandler}>Recuperar Password</button>
            </div>
        </div>
    </div>
    </main></Fragment>
}

export default ForgotPassword;