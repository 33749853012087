import { Fragment, useState } from "react"
import { TextField, Button, LinearProgress, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material"
import { useSelector } from 'react-redux'
import { getFunctions, httpsCallable } from 'firebase/functions'
import firebaseApp from '../../firebase/firebaseApp'

const functions = getFunctions(firebaseApp)

const EditCategory = ({close, updateCategory, familyName, category}) => {
    const [name, setName] = useState(category)
    const [showProgress, setShowProgress] = useState(false)
    const profile = useSelector(state => state.user.profile)
    const [message, setMessage] = useState('')
    const [error, setError] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)

    const changeNameHandler = e => {
        setName(e.target.value)
    }

    const updateCategoryHandler = () => {
        setShowProgress(true)
        const updateCategoryFunction = httpsCallable(functions, 'updateCategory')
        updateCategoryFunction({familyName, oldName: category, newName: name}).then(result => {
            if (result.data.success) {
                console.log(result.data.payload)
                updateCategory(category, name)
                close()
            } else {
                console.error(result.data.message)
                setError(true)
                setMessage(result.data.message)
            }
        }).catch(error => {
            console.error(error)
            setError(true)
            setMessage(error.message)
        }).finally(() => {
            setShowProgress(false)
        })
    }

    const deleteCategoryHandler = () => {
        setShowProgress(true)
    }

    const closeWindowHandler = () => {
        close()
    }

    const closeDialogHandler = () => {
        setOpenDialog(false)
    }

    return <Fragment>
        <TextField label="Name" fullWidth size="small" required onChange={changeNameHandler} value={name} />
        {(profile === 'administrator' || profile === 'operator') && <Button onClick={updateCategoryHandler} variant='outlined' size='small' sx={{ marginTop: '10px' }}>Update</Button>}
        {(profile === 'administrator' || profile === 'operator') && <Button onClick={deleteCategoryHandler} variant='outlined' size='small' sx={{ marginTop: '10px', marginLeft: '10px' }} color="warning">Delete</Button>}
        <Button onClick={closeWindowHandler} variant='outlined' size='small' sx={{ marginTop: '10px', marginLeft: '10px' }} color="secondary">Cancel</Button>
        {error && message && <Typography color="secondary" variant="h6" sx={{ marginTop: '10px' }}>{{ message }}</Typography>}
        {showProgress && <LinearProgress sx={{ marginTop: '10px'}}/>}
        <Dialog open={openDialog} onClose={closeDialogHandler} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography sx={{ fontWeight: 'bold' }}>Warning</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography>Are you sure to delete the category</Typography>
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>
    </Fragment>
}

export default EditCategory