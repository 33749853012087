import {TableContainer, Table, TableBody, TableCell, TableHead, Box, TableRow} from '@mui/material'
import TableHeaderCell from '../UI/TableHeaderCell'

const ListLeadTimes = ({leadTimes}) => {
    return <TableContainer component={Box}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeaderCell>From</TableHeaderCell>
                    <TableHeaderCell>To</TableHeaderCell>
                    <TableHeaderCell>Price FOB (US$)</TableHeaderCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {leadTimes.map((leadTime, index) => {
                    return <TableRow key={index}>
                        <TableCell>{leadTime.from}</TableCell>
                        <TableCell>{leadTime.to}</TableCell>
                        <TableCell>{leadTime.duration}</TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </TableContainer>
}

export default ListLeadTimes