import {Routes, Route} from 'react-router-dom';
import Login from './pages/auth/Login';
import Home from './pages/Home';
import GuestLayout from './components/layouts/Guest';
import UserLayout from './components/layouts/User';
import {Fragment, useEffect} from 'react';
import Logout from './pages/auth/Logout';
import ForgotPassword from './pages/auth/ForgotPassword';
import EmailNotVerified from './pages/auth/EmailNotVerified';
import ChangePassword from './pages/auth/ChangePassword';
import { useDispatch } from 'react-redux';
import firebaseApp from './firebase/firebaseApp';
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import { userComeBack } from './app/userActions';
import { logout } from './app/userSlice'
import Families from './pages/Families';
import Products from './pages/Products';

const auth = getAuth(firebaseApp)

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user)
      {
        dispatch(userComeBack({id: user.uid, email: user.email, emailVerified: user.emailVerified}));
      }
      else 
      {
        console.log('El usuario no se encuentra logueado');
        dispatch(logout());
      }
    });
  }, [dispatch])
  
  return <Fragment>
      <Routes>
        <Route path="/" element={<UserLayout><Home/></UserLayout>} />
        <Route path="login" element={<GuestLayout><Login/></GuestLayout>} />
        <Route path="logout" element={<GuestLayout><Logout /></GuestLayout>} />
        <Route path="forgot" element={<GuestLayout><ForgotPassword/></GuestLayout>} />
        <Route path="emailnotverified" element={<GuestLayout><EmailNotVerified/></GuestLayout>} />
        <Route path="changepassword" element={<GuestLayout><ChangePassword/></GuestLayout>} />
        <Route path="families" element={<UserLayout><Families/></UserLayout>} />
        <Route path="products" element={<UserLayout><Products/></UserLayout>} />
      </Routes>
    </Fragment>;
}

export default App;
