import styles from './Login.module.css'
import { Navigate } from 'react-router-dom'
import { useState, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { userLogin } from '../../app/userActions'
import alaxs_logo from '../../img/alaxs_logo.png'

const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const userLoggedIn = useSelector((state) => state.user.loggedIn)
    const userError = useSelector((state) => state.user.error)

    const dispatch = useDispatch()

    const loginHandler = () => {
        if (email.length > 0 && password.length > 0) {
            dispatch(userLogin({email, password}))
        }
    }

    const changeEmailHandler = e => {
        setEmail(e.target.value)
    }

    const changePasswordHandler = e => {
        setPassword(e.target.value)
    }

    return <Fragment>
        {userLoggedIn && <Navigate to="/" />}
        <main className={styles.main}>
    <div className={styles.container}>
        <div className={styles.left} />
        <div className={styles.right}>
            <div className={styles.logo}>
                <img src={alaxs_logo} alt="Logo ALAXS" />
            </div>
            <div className={styles.controls}>
                <input type="email" placeholder="Email" className={styles.textinput} onChange={changeEmailHandler} />
                <input type="password" placeholder="Password" className={styles.textinput} onChange={changePasswordHandler}/>
                <a href="/forgot" className={styles.link}>I forgot my password</a>
                {userError && <span className={styles.error}>An error has happened.</span>}
                <button className={styles.button} onClick={loginHandler}>Login</button>
            </div>
        </div>
    </div>
    </main></Fragment>
}

export default Login;