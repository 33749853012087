import GenericCard from '../components/UI/GenericCard'
import ListFamilies from '../components/families/ListFamilies'
import { useState } from 'react'


const Families = () => {
    const [title, setTitle] = useState('Families List')

    const changeTitle = (the_title) => {
        setTitle(the_title)
    }

    return <GenericCard title={title}>
        <ListFamilies changeTitle={changeTitle}/>
    </GenericCard>
}

export default Families