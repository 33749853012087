import { TableContainer, Table, Box, TableHead, TableRow, TableBody, TableCell, 
    IconButton, Button, Dialog, DialogTitle, DialogContent, Typography} from "@mui/material"
import TableHeaderCell from '../UI/TableHeaderCell'
import { useEffect, useState, Fragment } from "react"
import { getFunctions, httpsCallable } from 'firebase/functions'
import firebaseApp from '../../firebase/firebaseApp'
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewFamily from "./ViewFamily"
import { useSelector } from 'react-redux';
import { LinearProgress  } from "@mui/material"
import NewFamily from "./NewFamily"

const functions = getFunctions(firebaseApp)

const ListFamilies = () => {

    const [families, setFamilies] = useState([])
    const [showViewFamily, setShowViewFamily] = useState(false)
    const [currentFamily, setCurrentFamily] = useState(null)
    const [showProgress, setShowProgress] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)

    const profile = useSelector(state => state.user.profile)

    useEffect(() => {
        setShowProgress(true)
        const fetchFamilies = () => {
            const listFamilies = httpsCallable(functions, 'listFamilies')
            listFamilies().then(response => {
                console.log(response.data)
                setFamilies(response.data)
                setShowProgress(false)
            })
        }
        fetchFamilies()
    }, [])

    const showFamilyHandler = (family, e) => {
        console.log(family)
        setCurrentFamily(family)
        setShowViewFamily(true)
    }

    const hideFamilyHandler = () => {
        setCurrentFamily(null)
        setShowViewFamily(false)
    }

    const newFamilyHandler = () => {
        setDialogOpen(true)
    }

    const closeDialogHandler = () => {
        setDialogOpen(false)
    }

    const addFamilyHandler = (payload) => {
        const copyFamilies = [...families]
        copyFamilies.push(payload.name)
        setFamilies(copyFamilies)
    }

    const updateFamilyHandler = (oldName, newName) => {
        const copyFamilies = [...families]
        const foundFamily = copyFamilies.find(name => name === oldName)
        if (foundFamily) {
            const otherFamilies = copyFamilies.filter(name => name !== oldName)
            otherFamilies.push(newName)
            setFamilies(otherFamilies)
        } else {
            console.error('The family does not exist')
        }
    }

    return <Fragment>
        {!showViewFamily && <Fragment><TableContainer component={Box}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>Name</TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {families.map(family => <TableRow key={family}>
                        <TableCell sx={{width: '100%'}}>{family}</TableCell>
                        <TableCell><IconButton onClick={showFamilyHandler.bind(this, family)}><VisibilityIcon color="primary"/></IconButton></TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
        {(profile === 'administrator' || profile === 'operator') && <Button size="small" variant="outlined" color="primary" sx={{ marginTop: '10px'}} onClick={newFamilyHandler}>New Family</Button>}
        {showProgress && <LinearProgress sx={{ marginTop: '10px'}}/>}
        </Fragment>}
        {showViewFamily && <ViewFamily family={currentFamily} hideFamily={hideFamilyHandler} updateFamily={updateFamilyHandler} />}
        <Dialog open={dialogOpen} onClose={closeDialogHandler} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography sx={{ fontWeight: 'bold' }}>Add Family</Typography>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ marginTop: '5px' }}>
                    <NewFamily close={closeDialogHandler} addFamily={addFamilyHandler} />
                </Box>
            </DialogContent>
        </Dialog>
    </Fragment>
}

export default ListFamilies