import {Card, CardHeader, Typography, CardContent} from '@mui/material';

const GenericCard = (props) => {
    return <Card>
        <CardHeader subheader={<Typography variant="subtitle2" sx={{color:"#444"}}>{props.title}</Typography>} sx={{backgroundColor: '#ddd'}} />
        <CardContent>
            {props.children}
        </CardContent>
    </Card> 
}

export default GenericCard;