import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import SummarizeIcon from '@mui/icons-material/Summarize';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import { Navigate, Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import Message from '../../pages/Message';
import { Fragment } from 'react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PasswordIcon from '@mui/icons-material/Password';
import { Container } from '@mui/material';
import CategoryIcon from '@mui/icons-material/Category';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const emailVerified = useSelector(state => state.user.emailVerified)

  const role = useSelector(state => state.user.role)
  const active = useSelector(state => state.user.active)
  const profile = useSelector(state => state.user.profile)

  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const userLoggedIn = useSelector(state => state.user.loggedIn);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const changePasswordHandler = () => {
    setAnchorEl(null);
    navigate("/changepassword", { replace:true });
  }

  if (role !== undefined && role !== "supplier")
  {
    return <Message url="/logout" title="Access denied" message="Your account does not have access to the system." buttonTitle="Return"/>
  }

  if (active !== undefined && !active)
  {
    return <Message url="/logout" title="Account not active" message="Your account has been deactivated." buttonTitle="Return"/>
  }

  if (profile !== undefined && profile !== "administrator" && profile !== "operator" && profile !== "reader")
  {
    return <Message url="/logout" title="No profile found" message="Your account does not have an associated profile." buttonTitle="Exit" />
  }

  return <Fragment>
    {emailVerified !== undefined && !emailVerified && <Navigate to="/emailnotverified" replace={true}/>}
    {userLoggedIn !== undefined && !userLoggedIn && <Navigate to="login" />}
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1}}>
            ALAXS Suppliers
          </Typography>
          <Box>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={changePasswordHandler}>
                  <ListItemIcon>
                        <PasswordIcon />
                  </ListItemIcon>
                  <ListItemText>Change password</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem component={Link} to="/logout">
                  <ListItemIcon>
                        <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText>Exit</ListItemText>
                </MenuItem>
              </Menu>
            </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
            <ListItem key="Families" disablePadding>
              <ListItemButton component={Link} to="/families">
                <ListItemIcon>
                  <CategoryIcon />
                </ListItemIcon>
                <ListItemText primary="Families" />
              </ListItemButton>
            </ListItem>
            <ListItem key="Categories" disablePadding>
              <ListItemButton component={Link} to="/products">
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Products" />
              </ListItemButton>
            </ListItem>
        </List>
        <Divider />
        <List>
            <ListItem key="Opened Orders" disablePadding>
                <ListItemButton>
                <ListItemIcon>
                    <ProductionQuantityLimitsIcon />
                </ListItemIcon>
                <ListItemText primary="Opened Orders" />
                </ListItemButton>
            </ListItem>
            <ListItem key="Billing" disablePadding>
                <ListItemButton>
                <ListItemIcon>
                    <RequestPageIcon />
                </ListItemIcon>
                <ListItemText primary="Billing" />
                </ListItemButton>
            </ListItem>
            <ListItem key="Other Documents" disablePadding>
                <ListItemButton>
                <ListItemIcon>
                    <SummarizeIcon />
                </ListItemIcon>
                <ListItemText primary="Other Documents" />
                </ListItemButton>
            </ListItem>
            <ListItem key="Orders Status" disablePadding>
                <ListItemButton>
                <ListItemIcon>
                    <EmojiFlagsIcon/>
                </ListItemIcon>
                <ListItemText primary="Orders Status" />
                </ListItemButton>
            </ListItem>
        </List>
        <Divider />
        <List>
            <ListItem key="Quit" disablePadding>
              <ListItemButton component={Link} to="/logout">
                <ListItemIcon>
                    <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Quit" />
              </ListItemButton>
            </ListItem>
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Container maxWidth='lg'>
          {props.children}
        </Container>
      </Main>
    </Box>
    </Fragment>
  ;
}
