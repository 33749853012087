import { login, logout, setInfo, setError } from './userSlice';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '../firebase/firebaseApp';

const auth = getAuth(firebaseApp);
const functions = getFunctions(firebaseApp);

export const userLogin = (payload) => {
    return async (dispatch) => {
        signInWithEmailAndPassword(auth, payload.email, payload.password)
        .then(() => {
            dispatch(setError({error: false}));
        })
        .catch(error => {
            dispatch(setError({error: true}));
            console.log(error.message);
        })
    }
}

export const userLogout = () => {
    return async (dispatch) => {
        signOut(auth);
        dispatch(logout());
    }
}

export const userComeBack = (payload) => {
    return async (dispatch) => {
        dispatch(login({id: payload.id, email: payload.email, emailVerified: payload.emailVerified}));
        const getUserCallable = httpsCallable(functions, 'getUser');
        getUserCallable({data: null})
        .then(({data:userInfo}) => {
            if (userInfo.role)
            {
                dispatch(setInfo({role: userInfo.role, profile: userInfo.profile, active: userInfo.active}));
            }
            else {
                throw new Error(`User ${payload.email} does not have role.`);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }
}