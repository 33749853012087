import { Fragment, useState } from "react"
import { TextField, LinearProgress, Button } from '@mui/material'
import { useSelector } from 'react-redux'
import { getFunctions, httpsCallable } from 'firebase/functions'
import firebaseApp from '../../firebase/firebaseApp'

const functions = getFunctions(firebaseApp)

const NewCategory = ({familyName, close, addCategory}) => {
    const [name, setName] = useState('')
    const [showProgress, setShowProgress] = useState(false)
    const profile = useSelector(state => state.user.profile)

    const createCategoryHandler = e => {
        setShowProgress(true)
        const createCategoryFunction = httpsCallable(functions, 'createCategory')
        createCategoryFunction({familyName, name}).then(response => {
            if (response.data.success) {
                addCategory(response.data.payload)
                setShowProgress(false)
                close()
            } else {
                console.log(response.data.error)
            }
            
        }).catch(error => {
            console.error(error)
        })
    }

    const closeWindowHandler = () => {
        close()
    }

    const changeNameHandler = e => {
        setName(e.target.value)
    }

    return <Fragment>
        <TextField label="Name" fullWidth size="small" required onChange={changeNameHandler} value={name} />
        {(profile === 'administrator' || profile === 'operator') && <Button variant='outlined' size='small' onClick={createCategoryHandler} sx={{ marginTop: '20px', marginRight: '10px'}}>Create Category</Button>}
        <Button variant='outlined' size='small' onClick={closeWindowHandler} color='secondary' sx={{ marginTop: '20px'}}>Cancel</Button>
        {showProgress && <LinearProgress sx={{ marginTop: '10px' }}/>}
    </Fragment>
}

export default NewCategory