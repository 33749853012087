import {TableContainer, Table, TableBody, TableCell, TableHead, Box, TableRow} from '@mui/material'
import TableHeaderCell from '../UI/TableHeaderCell'

const ListSizes = ({sizes}) => {
    return <TableContainer component={Box}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeaderCell>Size</TableHeaderCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {sizes.map((size, index) => {
                    return <TableRow key={index}>
                        <TableCell>{size}</TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </TableContainer>
}

export default ListSizes