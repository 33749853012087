import { Button, Card, CardContent, CardMedia, CardActions, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ideaImage from '../img/idea_small.png';

const Message = (props) => {
    const navigate = useNavigate();

    const buttonHandler = () => {
        navigate(props.url, {replace: true});
    }

    return (
        <Card sx={{ maxWidth: 345, marginLeft: "auto", marginRight: "auto", marginTop: "50px" }}>
            <CardMedia component="img" height="140" image={ideaImage} alt="email" />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {props.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {props.message}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" onClick={buttonHandler}>{props.buttonTitle}</Button>
            </CardActions>
        </Card>
    );
}

export default Message;