import {ImageList, ImageListItem} from '@mui/material'

const ListImages = ({images}) => {
    return <ImageList cols={1}>
        {images.map((item, index) => {
            return <ImageListItem key={index}>
                <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}${item}`} alt={`Product${index}`}/>
            </ImageListItem>
        })}
    </ImageList>
}

export default ListImages