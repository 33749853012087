import {TableContainer, Table, TableBody, TableCell, TableHead, Box, TableRow} from '@mui/material'
import TableHeaderCell from '../UI/TableHeaderCell'

const ListPriceRanges = ({priceRanges}) => {
    return <TableContainer component={Box}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeaderCell>From</TableHeaderCell>
                    <TableHeaderCell>To</TableHeaderCell>
                    <TableHeaderCell>Price FOB (US$)</TableHeaderCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {priceRanges.map((priceRange, index) => {
                    return <TableRow key={index}>
                        <TableCell>{priceRange.from}</TableCell>
                        <TableCell>{priceRange.to}</TableCell>
                        <TableCell>{priceRange.price}</TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </TableContainer>
}

export default ListPriceRanges