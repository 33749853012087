import { TableContainer, Table, Box, TableHead, TableRow, TableBody, TableCell, Button, 
    IconButton, Dialog, DialogTitle, DialogContent, Typography } from "@mui/material"
import TableHeaderCell from '../UI/TableHeaderCell'
import { useEffect, useState, Fragment } from 'react'
import { getFunctions, httpsCallable } from 'firebase/functions'
import firebaseApp from '../../firebase/firebaseApp'
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material'
import NewCategory from './NewCategory'
import EditCategory from "./EditCategory"

const functions = getFunctions(firebaseApp)

const ListCategories = ({family, showControls}) => {
    const [categories, setCategories] = useState([])
    const profile = useSelector(state => state.user.profile)
    const [showProgress, setShowProgress] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [currentCategory, setCurrentCategory] = useState(null)
    const [editDialogOpen, setEditDialogOpen] = useState(false)
    
    useEffect(() => {
        setShowProgress(true)
        const fetchCategories = () => {
            const listCategories = httpsCallable(functions, 'listCategories')
            listCategories({family: family}).then(response => {
                console.log(response.data)
                setCategories(response.data)
                setShowProgress(false)
            })
        }
        fetchCategories()
    }, [family])

    const newCategoryHandler = () => {
        setDialogOpen(true)
    }

    const addCategoryHandler = (payload) => {
        console.log(payload.name)
        const copyCategories = [...categories]
        const foundCategory = copyCategories.find(category => category === payload.name)
        if (foundCategory) {
            console.error('The category already exists.')
        } else {
            copyCategories.push(payload.name)
            setCategories(copyCategories)
        }
    }

    const editCategoryHandler = (category, e) => {
        setCurrentCategory(category)
        setEditDialogOpen(true)
    }

    const closeDialogHandler = () => {
        setDialogOpen(false)
    }

    const closeEditDialogHandler = () => {
        setEditDialogOpen(false)
    }

    const updateCategoryHandler = (oldName, newName) => {
        console.log(`Old Name: ${oldName}`)
        console.log(`New Name: ${newName}`)
        const copyCategories = [...categories]
        const foundCategory = copyCategories.find(category => category === oldName)
        if (foundCategory) {
            // Verify that the new name does not exist
            const newCategory = copyCategories.find(category => category === newName)
            if (newCategory) {
                console.error(`The new name ${newName} already exists.`)
            } else {
                const otherCategories = copyCategories.filter(category => category !== oldName)
                otherCategories.push(newName)
                otherCategories.sort()
                setCategories(otherCategories)
            }
        } else {
            console.error(`The category ${oldName} was not found.`)
        }
    }

    return <Fragment><TableContainer component={Box}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeaderCell>Name</TableHeaderCell>
                    {showControls && <TableHeaderCell></TableHeaderCell>}
                </TableRow>
            </TableHead>
            <TableBody>
                {categories.map(c => {
                    return <TableRow key={c}>
                        <TableCell sx={{width: '100%'}}>{c}</TableCell>
                        {showControls && <TableCell><IconButton onClick={editCategoryHandler.bind(this, c)}><EditIcon color="primary"/></IconButton></TableCell>}
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </TableContainer>
    {(profile==='administrator' || profile==='operator') && showControls && <Button size="small" variant="outlined" sx={{ marginTop: '10px'}} onClick={newCategoryHandler}>New Category</Button>}
    {showProgress && <LinearProgress/>}
    <Dialog open={dialogOpen} onClose={closeDialogHandler} maxWidth="sm" fullWidth>
        <DialogTitle>
            <Typography sx={{ fontWeight: 'bold' }}>New Category</Typography>
        </DialogTitle>
        <DialogContent>
            <Box sx={{ marginTop: '5px' }}>
                <NewCategory close={closeDialogHandler} addCategory={addCategoryHandler} familyName={family} />
            </Box>
        </DialogContent>
    </Dialog>
    <Dialog open={editDialogOpen} onClose={closeEditDialogHandler} maxWidth='sm' fullWidth>
        <DialogTitle>
            <Typography sx={{ fontWeight: 'bold' }}>Edit Category</Typography>
        </DialogTitle>
        <DialogContent>
            <Box sx={{ marginTop: '5px' }}>
                <EditCategory close={closeEditDialogHandler} updateCategory={updateCategoryHandler} familyName={family} category={currentCategory} />
            </Box>
        </DialogContent>
    </Dialog>
    
    </Fragment>
}

export default ListCategories