import { Fragment, useState } from "react"
import { TextField, Button, LinearProgress } from "@mui/material"
import { getFunctions, httpsCallable } from 'firebase/functions'
import firebaseApp from '../../firebase/firebaseApp'
import { useSelector } from "react-redux"

const functions = getFunctions(firebaseApp)

const NewFamily = ({close, addFamily}) => {
    const [name, setName] = useState('')
    const [showProgress, setShowProgress] = useState(false)
    const profile = useSelector(state => state.user.profile)

    const changeNameHandler = e => {
        setName(e.target.value)
    }

    const createFamilyHandler = () => {
        setShowProgress(true)
        const createFamilyFunction = httpsCallable(functions, 'createFamilyV2')
        createFamilyFunction({name}).then(response => {
            if (response.data.success) {
                addFamily(response.data.payload)
                setShowProgress(false)
                close()
            }
            else {
                console.error(response.data.message)
            }
        }).catch(error => {
            console.error(error)
        })
    }

    const closeWindowHandler = () => {
        close()
    }

    return <Fragment>
        <TextField label="Name" fullWidth size="small" required onChange={changeNameHandler} value={name} />
        {(profile === 'administrator' || profile === 'operator') && <Button variant="outlined" size="small" sx={{ marginTop: '20px' }} onClick={createFamilyHandler}>Add Family</Button>}
        <Button variant='outlined' size='small' sx={{ marginTop: '20px', marginLeft: '5px' }} onClick={closeWindowHandler} color='secondary'>Cancel</Button>
        {showProgress && <LinearProgress sx={{ marginTop: '10px' }} />}
    </Fragment>
}

export default NewFamily