// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD68lWbc3NjfH2o4SYg3mSPp9Qqmbi2vK4",
  authDomain: "myalaxs-firebase.firebaseapp.com",
  projectId: "myalaxs-firebase",
  storageBucket: "myalaxs-firebase.appspot.com",
  messagingSenderId: "438502362160",
  appId: "1:438502362160:web:3e8aad2aa7eecbef54f1f5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;