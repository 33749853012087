import {TableContainer, Table, TableBody, TableCell, TableHead, Box, TableRow} from '@mui/material'
import TableHeaderCell from '../UI/TableHeaderCell'

const ListDescription = ({descriptions}) => {
    return <TableContainer component={Box}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeaderCell>Description</TableHeaderCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {descriptions.map((description, index) => {
                    return <TableRow key={index}>
                        <TableCell>{description}</TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </TableContainer>
}

export default ListDescription