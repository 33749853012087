import {TableContainer, Table, TableBody, TableCell, TableHead, Box, TableRow} from '@mui/material'
import TableHeaderCell from '../UI/TableHeaderCell'

const ListDetails = ({details}) => {
    return <TableContainer component={Box}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeaderCell>Name</TableHeaderCell>
                    <TableHeaderCell>Value</TableHeaderCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {details.map(detail => {
                    return <TableRow key={detail.name}>
                        <TableCell>{detail.name}</TableCell>
                        <TableCell>{detail.value}</TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </TableContainer>
}

export default ListDetails