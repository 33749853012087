import { Box, TextField, Tab, Button, LinearProgress} from "@mui/material"
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useState} from 'react'
import { useSelector } from 'react-redux';
import { getFunctions, httpsCallable } from 'firebase/functions'
import firebaseApp from '../../firebase/firebaseApp'
import ListCategories from "../categories/ListCategories";

const functions = getFunctions(firebaseApp)

const ViewFamily = ({family, hideFamily, updateFamily}) => {
    
    const [tabValue, setTabValue] = useState("categories")
    const [showControls, setShowControls] = useState(false)
    const [name, setName] = useState(family)
    const [showProgress, setShowProgress] = useState(false)

    const profile = useSelector(state => state.user.profile)
    
    const changeTab = (e, value) => {
        setTabValue(value)
    }

    const editFamilyHandler = () => {
        setShowControls(true)
    }

    const cancelFamilyHandler = () => {
        setShowControls(false)
    }

    const changeNameHandler = e => {
        setName(e.target.value)
    }

    const updateFamilyHandler = () => {
        // Call Function to update family
        setShowProgress(true)
        const updateFamilyFunction = httpsCallable(functions, 'updateFamilyV2')
        updateFamilyFunction({oldName: family, newName: name}).then(response => {
            if (response.data.success) {
                updateFamily(family, name)
                setShowProgress(false)
                setShowControls(false)
            }
        }).catch(error => {
            console.error(error)
        })
    }

    return <Box sx={{ marginTop: '5px' }}>
        <TextField label="Nombre" size="small" fullWidth type="text" disabled={!showControls} value={name} sx={{ marginTop: '10px'}} onChange={changeNameHandler} />
        <TabContext value={tabValue}>
            <Box sx={{ borderBottom:1, borderColor: 'divider', marginTop: "20px"}}>
                <TabList onChange={changeTab}>
                    <Tab value="categories" label="Categories" />
                </TabList>
            </Box>
            <TabPanel value="categories">
                <ListCategories family={family} showControls={showControls}/>
            </TabPanel>
        </TabContext>
        {(profile === 'administrator' || profile === 'operator') && !showControls && <Button size="small" variant="outlined" onClick={editFamilyHandler}>Edit Family</Button>}
        {(profile === 'administrator' || profile === 'operator') && !showControls && <Button size="small" variant="outlined" onClick={hideFamily} sx={{marginLeft: '10px'}} color="secondary">Return</Button>}
        {(profile === 'administrator' || profile === 'operator') && showControls && <Button size="small" variant="outlined" color="primary" sx={{marginLeft: '10px'}} onClick={updateFamilyHandler}>Save Changes</Button>}
        {(profile === 'administrator' || profile === 'operator') && showControls && <Button size="small" variant="outlined" color="secondary" sx={{marginLeft: '10px'}} onClick={cancelFamilyHandler}>Cancel</Button>}
        {showProgress && <LinearProgress sx={{ marginTop: '10px' }} />}
    </Box>
}

export default ViewFamily