import { Fragment, useState, useEffect } from "react"
import { FormControl, InputLabel, Select, MenuItem, LinearProgress, IconButton, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Box,  Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material"
import { getFunctions, httpsCallable } from 'firebase/functions'
import TableHeaderCell from "../UI/TableHeaderCell"
import firebaseApp from '../../firebase/firebaseApp'
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewProduct from "./ViewProduct";

const functions = getFunctions(firebaseApp)

const ListProducts = () => {
    const [family, setFamily] = useState('')
    const [families, setFamilies] = useState([])

    const [category, setCategory] = useState('')
    const [categories, setCategories] = useState([])

    const [showProgress, setShowProgress] = useState(false)

    const [products, setProducts] = useState([])
    const [openDialog, setOpenDialog] = useState(false)

    const [product, setProduct] = useState(null)

    const changeFamilyHandler = (e) => {
        setFamily(e.target.value)

        // Call api to retrieve categories
        setShowProgress(true)
        const listCategories = httpsCallable(functions, 'listCategories')
        listCategories({family: e.target.value}).then(response => {
            console.log('List of categories')
            console.log(response.data)
            setCategories(response.data)
            setShowProgress(false)
        })
    }

    const changeCategoryHandler = (e) => {
        setCategory(e.target.value)
        // List Products by Family and Category
        setShowProgress(true)
        const listProducts = httpsCallable(functions, 'listProducts')
        console.log(process.env.LIMIT)
        listProducts({limit: Number(process.env.REACT_APP_LIMIT), family: family, category: e.target.value}).then(response => {
            setProducts(response.data)
            setShowProgress(false)
            console.log('List of Products')
            console.log(response.data)
        })
    }

    useEffect(() => {
        setShowProgress(true)
        const fetchFamilies = () => {
            const listFamilies = httpsCallable(functions, 'listFamilies')
            listFamilies().then(response => {
                setFamilies(response.data)
                console.log(response.data)
                setShowProgress(false)
            })
        }
        fetchFamilies()
    }, [])

    const showProductHandler = (product, e) => {
        console.log(product)
        setProduct(product)
        setOpenDialog(true)
    }

    const closeDialogHandler = () => {
        
        setOpenDialog(false)
    }

    return <Fragment>
        <FormControl fullWidth>
            <InputLabel id="label-family" size="small">Family</InputLabel>
            <Select
                labelId="label-family"
                id="family"
                value={family}
                label="Family"
                onChange={changeFamilyHandler}
                size="small"
            >
                {families.map(family => <MenuItem key={family} value={family}>{family}</MenuItem>)}
                
            </Select>
        </FormControl>
        <FormControl fullWidth sx={{ marginTop: '15px'}}>
            <InputLabel id="label-category" size="small">Category</InputLabel>
            <Select
                labelId="label-category"
                id="category"
                value={category}
                label="Category"
                onChange={changeCategoryHandler}
                size="small"
            >
                {categories.map(category => <MenuItem key={category} value={category}>{category}</MenuItem>)}
                
            </Select>
        </FormControl>
        <TableContainer component={Box}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>Name</TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {products.map(product => {
                        return <TableRow key={product.id}>
                            <TableCell sx={{width: '100%'}}>{product.name}</TableCell>
                            <TableCell><IconButton onClick={showProductHandler.bind(this, product)}><VisibilityIcon color="primary"/></IconButton></TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        {showProgress && <LinearProgress sx={{ marginTop: '10px'}}/>}
        <Dialog open={openDialog} onClose={closeDialogHandler} fullWidth={true} maxWidth="md">
            <DialogTitle>Product Details</DialogTitle>
            <DialogContent>
                <ViewProduct product={product} />
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' color='primary'>Edit Product</Button>
                <Button variant='outlined' color='secondary' onClick={closeDialogHandler}>Close</Button>
            </DialogActions>
        </Dialog>
    </Fragment>
}

export default ListProducts