import { Box, LinearProgress, TextField, Tab} from "@mui/material"
import { useEffect, useState, Fragment } from "react"
import firebaseApp from '../../firebase/firebaseApp'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import ListDetails from "./ListDetails"
import ListDescription from "./ListDescriptions"
import ListPriceRanges from "./ListPriceRanges"
import ListLeadTimes from "./ListLeadTimes"
import ListSizes from "./ListSizes"
import ListImages from "./ListImages"

const functions = getFunctions(firebaseApp)

const ViewProduct = ({product}) => {

    const [showProgress, setShowProgress] = useState(false)
    const [productDetail, setProductDetail] = useState(null)
    const [tabValue, setTabValue] = useState("details")

    useEffect(() => {
        setShowProgress(true)
        const fetchProduct = () => {
            const getProduct = httpsCallable(functions, 'getProduct')
            getProduct({id: product.id}).then(response => {
                setProductDetail(response.data)
                setShowProgress(false)
                console.log(response.data)
            })
        }
        fetchProduct()
    }, [product.id])

    const changeTabHandler = (e, value) => {
        setTabValue(value)
    }

    return <Box sx={{ marginTop: '5px' }}>
        {!showProgress && productDetail && 
            <Fragment>
                <TextField label="Code" size="small" fullWidth type="text" disabled value={productDetail.code??""} sx={{ marginTop: '10px'}} />
                <TextField label="Name" size="small" fullWidth type="text" disabled value={productDetail.name??""} sx={{ marginTop: '10px'}} />
                <TextField label="Family" size="small" fullWidth type="text" disabled value={productDetail.family??""} sx={{ marginTop: '10px'}} />
                <TextField label="Category" size="small" fullWidth type="text" disabled value={productDetail.category??""} sx={{ marginTop: '10px'}} />
                <TextField label="Sub Category" size="small" fullWidth type="text" disabled value={productDetail.subCategory??""} sx={{ marginTop: '10px'}} />
                <TextField label="Unit" size="small" fullWidth type="text" disabled value={productDetail.unit??""} sx={{ marginTop: '10px'}} />
                <TextField label="Minimum Amount" size="small" fullWidth type="number" disabled value={productDetail.minimumAmount??""} sx={{ marginTop: '10px'}} />
                <TextField label="Band From" size="small" fullWidth type="number" disabled value={productDetail.bandFrom??""} sx={{ marginTop: '10px'}} />
                <TextField label="Band To" size="small" fullWidth type="number" disabled value={productDetail.bandTo??""} sx={{ marginTop: '10px'}} />
                <TextField label="Incoterm" size="small" fullWidth type="text" disabled value={productDetail.incoterm??""} sx={{ marginTop: '10px'}} />
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom:1, borderColor: 'divider', marginTop: "20px"}}>
                        <TabList onChange={changeTabHandler}>
                            <Tab value="details" label="Details" />
                            <Tab value="descriptions" label="Descriptions" />
                            <Tab value="images" label="Images" />
                            <Tab value="leadTimes" label="Lead Times" />
                            <Tab value="priceRanges" label="Price Ranges" />
                            <Tab value="sizes" label="Sizes" />
                        </TabList>
                    </Box>
                    <TabPanel value="details">
                        <ListDetails details={productDetail.details} />
                    </TabPanel>
                    <TabPanel value="descriptions">
                        <ListDescription descriptions={productDetail.descriptions} />
                    </TabPanel>
                    <TabPanel value="images">
                        <ListImages images={productDetail.images} />
                    </TabPanel>
                    <TabPanel value="leadTimes">
                        <ListLeadTimes leadTimes={productDetail.leadTimes} />
                    </TabPanel>
                    <TabPanel value="priceRanges">
                        <ListPriceRanges priceRanges={productDetail.priceRanges} />
                    </TabPanel>
                    <TabPanel value="sizes">
                        <ListSizes sizes={productDetail.sizes} />
                    </TabPanel>
                </TabContext>
            </Fragment>
        }
        
        {showProgress && <LinearProgress />}
    </Box>
}

export default ViewProduct