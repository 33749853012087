import { getAuth, signOut } from "firebase/auth";
import firebaseApp from '../../firebase/firebaseApp';
import { Navigate } from 'react-router-dom';

const Logout = () => {
    const auth = getAuth(firebaseApp);
    signOut(auth);

    return <Navigate to="/login" />
}

export default Logout;